var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divBox"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"排序","rules":[{ required: true, message: '请输入排序值', trigger: 'blur' }],"prop":"sort"}},[_c('v-input',{attrs:{"placeholder":"请输入排序值","width":250,"type":"number"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1),_c('el-form-item',{attrs:{"label":"企业名称","rules":[
        { required: true, message: '请输入企业名称', trigger: 'blur' },
      ],"prop":"name"}},[_c('v-input',{attrs:{"placeholder":"请输入企业名称","width":250,"maxlength":50},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"联系人","rules":[
        { required: true, message: '请输入企业联系人', trigger: 'blur' },
      ],"prop":"contactName"}},[_c('v-input',{attrs:{"placeholder":"请输入企业联系人","width":250,"maxlength":50},model:{value:(_vm.form.contactName),callback:function ($$v) {_vm.$set(_vm.form, "contactName", $$v)},expression:"form.contactName"}})],1),_c('el-form-item',{attrs:{"label":"手机号","rules":[
        { required: true, message: '请输入手机号', trigger: 'change' },
        { validator: _vm.validatePhone, trigger: 'change' },
      ],"prop":"contactMobile"}},[_c('div',{staticClass:"form-item"},[_c('v-input',{attrs:{"placeholder":"请输入手机号","disabled":_vm.isEdit},on:{"blur":_vm.handelInputBlur},model:{value:(_vm.form.contactMobile),callback:function ($$v) {_vm.$set(_vm.form, "contactMobile", $$v)},expression:"form.contactMobile"}}),(_vm.form.id)?_c('v-button',{staticStyle:{"margin-left":"10px"},attrs:{"text":"编辑"},on:{"click":_vm.handelClick}}):_vm._e()],1)]),_c('el-form-item',{attrs:{"label":"服务分类","rules":[
        { required: true, message: '请选择服务分类', trigger: 'blur' },
      ],"prop":"serviceType"}},[_c('v-select',{attrs:{"options":_vm.teacherSignList},model:{value:(_vm.form.serviceType),callback:function ($$v) {_vm.$set(_vm.form, "serviceType", $$v)},expression:"form.serviceType"}})],1),_c('el-form-item',{attrs:{"label":"企业图片","rules":[
        { required: true, message: '请上传企业图片', trigger: 'blur' },
      ],"prop":"picture"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.picture,"tips":"建议上传345px*165px图片"},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "picture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "picture", $event)}}})],1),_c('el-form-item',{attrs:{"label":"简介","rules":[{ required: true, message: '请输入简介', trigger: 'blur' }],"prop":"description"}},[_c('v-input',{attrs:{"placeholder":"请输入简介","type":"textarea"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }